import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { graphql } from "gatsby";
export const query = graphql`
    query {
        allAirtableVersion {
            nodes {
                data {
                Version
                Release_Date
                }
            }
        }
    }
`;
export const sortedVersions = versions => versions.sort((a, b) => {
  return +a.data.Version.replaceAll('.', '') < +b.data.Version.replaceAll('.', '') ? 1 : -1;
});
export const _frontmatter = {
  "menuLabel": "Download",
  "sortOrder": 1
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CButton = makeShortcode("CButton");
const layoutProps = {
  query,
  sortedVersions,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Download Caesars UI`}</h1>
    <p className="p-large">Package releases in zip format.</p>
    <p>{`Here's a zip file containing the full release of Caesars UI.  It has the following folder structure:  `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`caesars-ui/
    tokens/ // Token files for JS, Web, Tailwind, iOS and Android can be found here.
    // iOS: caesars-ui-tokens.swift, Android: CaesarsUiTokens.kt
    css/ // Related to JS component library
    utils/ // JS utility functions
    types/ // Related to web components / Typescript
    index.es.js
    index.js.map
    index.es.js.map
`}</code></pre>
    <div className="flex items-center">
    <h2>Caesars UI Latest (v{sortedVersions(props.data.allAirtableVersion.nodes)[0].data.Version})</h2>
    <CButton className="ml-4" link="" onClick={() => window.open(`/dist/caesars-ui.${sortedVersions(props.data.allAirtableVersion.nodes)[0].data.Version}.zip`, '_self')} mdxType="CButton">Download</CButton> 
    </div>
    <blockquote>
      <p parentName="blockquote">{`If you're coming from a js based project, you should install via npm `}<inlineCode parentName="p">{`@caesars-digital/caesars-ui`}</inlineCode>{`, if you're looking for token files for Android or iOS, download the zip and find the file in the associated directory in `}<inlineCode parentName="p">{`tokens/`}</inlineCode></p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      